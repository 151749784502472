<template>
  <div>
    <div class="lg:hidden">
      <div
        class="
          px-6
          py-2
          flex flex-col
          text-text-primary
          lg:mt-32 lg:min-h-screen
        "
      >
        <header class="py-2.5 text-lg flex text-text-alternate-1 pr-6">
          <button class="focus:outline-none" @click="$router.go(-1)">
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
          <h2 class="mx-auto font-semibold">Top Fundraisers</h2>
        </header>
        <main>
          <div class="flex gap-4 mt-4 mb-6 justify-between">
            <BModalSelectSM v-model="type" :options="typeOptions" />
            <BModalSelectSM v-model="time" :options="timeOptions" />
          </div>
          <BPageLoader v-if="isLoading && !data.fundraisers?.length" />
          <div v-else-if="data.fundraisers.length > 0">
            <router-link
              v-for="{ amount, title, fundraiserId } in data.fundraisers"
              :key="fundraiserId"
              :to="{ name: 'Overview', params: { slug: fundraiserId } }"
              class="
                block
                rounded
                p-4
                text-text-primary
                my-4
                border border-border-primary
              "
            >
              <h4 class="font-medium text-xs truncate">{{ title }}</h4>
              <div class="text-xs mt-3 font-bold text-lg">
                ₦ {{ amount.toLocaleString() }}
              </div>
            </router-link>
            <BPageLoader v-if="isLoading" />
          </div>
          <div class="flex pt-20 flex-col items-center justify-center" v-else>
            <span class="p-6 bg-bg-alternate-3 rounded-full">
              <ResultEmptyIcon class="fill-current h-10" />
            </span>
            <h4 class="font-medium text-md mt-8">No Fundraiser Found</h4>
          </div>
        </main>
      </div>
    </div>

    <div class="hidden lg:flex justify-center pt-8">
      <div class="b-card mt-24 pt-8 pb-12 mb-20">
        <div class="px-26 py-2 flex flex-col text-text-primary">
          <header class="py-2.5 text-lg flex text-text-alternate-1 pr-6">
            <button class="focus:outline-none" @click="$router.go(-1)">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto font-semibold">Top Fundraisers</h2>
          </header>
          <main>
            <div class="flex gap-4 mt-4 mb-6 justify-between">
              <BModalSelectSM v-model="type" :options="typeOptions" />
              <BModalSelectSM v-model="time" :options="timeOptions" />
            </div>
            <BPageLoader v-if="isLoading && !data.fundraisers?.length" />
            <div v-else-if="data.fundraisers.length > 0">
              <router-link
                v-for="{ amount, title, fundraiserId } in data.fundraisers"
                :key="fundraiserId"
                :to="{ name: 'Overview', params: { slug: fundraiserId } }"
                class="
                  block
                  rounded
                  p-4
                  text-text-primary
                  my-4
                  border border-border-primary
                "
              >
                <h4 class="font-medium text-xs truncate">{{ title }}</h4>
                <div class="text-xs mt-3 font-bold text-lg">
                  ₦ {{ amount.toLocaleString() }}
                </div>
              </router-link>
              <BPageLoader v-if="isLoading" />
            </div>
            <div class="flex pt-20 flex-col items-center justify-center" v-else>
              <span class="p-6 bg-bg-alternate-3 rounded-full">
                <ResultEmptyIcon class="fill-current h-10" />
              </span>
              <h4 class="font-medium text-md mt-8">No Fundraiser Found</h4>
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BModalSelectSM from '@/components/ui/BModalSelectSM';
import { watch, toRefs, reactive } from 'vue';
const typeOptions = ['All', 'One Time', 'Recurring'];
const timeOptions = Array.from({ length: 6 }).map((_, index) =>
  dayjs(dayjs().subtract(index, 'month')).format('MMMM YYYY')
);
import dayjs from 'dayjs';
import { useApi } from '@/cmp-functions/useApi';
import { getTopFundraisers } from '@/services/api';
import { useInfiniteScroll } from '@/cmp-functions/useInfiniteScroll';
const typesMap = { 'One Time': 'ONETIME', Recurring: 'RECURRING' };

const handlePaginationResponse = (pre, { data }) => {
  if (!data?.data) return pre;
  const { data: fundraisers, metadata } = data;

  return {
    fundraisers: [...pre.fundraisers, ...fundraisers],
    metaData: {
      offset: fundraisers.length + pre.fundraisers.length,
      ...metadata,
    },
  };
};

const getMonthYear = (time) => dayjs(`1 ${time}`).format('M/YYYY').split('/');

export default {
  name: 'TopFundraisers',

  components: { BModalSelectSM },

  setup() {
    const inputs = reactive({
      type: typeOptions[0],
      time: timeOptions[0],
    });

    const [response, fetchTopFundraisers] = useApi(
      getTopFundraisers,
      {
        fundraisers: [],
        metaData: { offset: 0, isLastPage: false },
      },
      handlePaginationResponse
    );

    const requestForTopFundraisers = ({ type, time }) => {
      response.data.fundraisers = [];
      const [month, year] = getMonthYear(time);
      fetchTopFundraisers({
        month,
        year,
        fundraiserType: typesMap[type],
        offset: 0,
      });
    };

    const fetchFundraiserData = () => {
      if (response.data.metaData.isLastPage || response.isLoading) return;
      const [month, year] = getMonthYear(inputs.time);
      fetchTopFundraisers({
        offset: response.data.metaData.offset,
        month,
        year,
        fundraiserType: typesMap[inputs.type],
      });
    };

    useInfiniteScroll(fetchFundraiserData, true);

    watch(inputs, requestForTopFundraisers);

    return { ...toRefs(response), ...toRefs(inputs), typeOptions, timeOptions };
  },
};
</script>
